<template>
  <div class="wrap">
    <header class="x-page-title">
      <h3>藏品上架</h3>
    </header>

    <main class="x-main">
      <!-- 标题类型 -->
      <el-card class="mb30">
        <div slot="header">
          <span>基本信息：</span>
        </div>
        <el-form
          class="x-form"
          ref="titleForm"
          size="small"
          label-width="160px"
          :rules="titleRules"
          :model="form"
        >
          <el-form-item label="所属品牌：" prop="brandID">
            <el-select
              placeholder="请选择所属品牌"
              v-model="form.brandID"
              @change="getCollectionOptions"
            >
              <el-option
                v-for="(item, index) in optionsBrand"
                :key="index"
                :label="item.brandName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="藏品集：" prop="collectionID">
            <el-select placeholder="请选择藏品集" v-model="form.collectionID">
              <el-option
                v-for="(item, index) in optionsCollection"
                :key="index"
                :label="item.collectionName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="藏品类型：" prop="sellType">
            <el-select
              placeholder="请选择藏品类型"
              v-model="form.sellType"
              @change="doSellTypeChange"
            >
              <el-option label="普通商品" value="1"></el-option>
              <el-option label="非卖品" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="藏品名称：" prop="NFTID">
            <el-select
              placeholder="请输入藏品名称"
              filterable
              :clearable="true"
              remote
              reserve-keyword
              :remote-method="doIdQuery"
              :loading="loading"
              v-model="form.NFTID"
              @change="setBaseFormOpenId"
            >
              <el-option
                v-for="item in optionId"
                :key="item.id"
                :label="item.NFTName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上架时间：" prop="onlineTime">
            <el-date-picker
              v-model="form.onlineTime"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="开售时间：" prop="onsellTime">
            <el-date-picker
              v-model="form.onsellTime"
              type="datetime"
              placeholder="选择日期时间"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="单价(单位:元)：" prop="unitPrice">
            <el-select
              placeholder="请选择单价"
              v-model="form.unitPrice"
              :disabled="priceDisable"
            >
              <el-option
                v-for="(item, index) in optionsPrice"
                :key="index"
                :label="item.Price"
                :value="item.Price"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="藏品名称：" prop="NFTName">
            <el-input v-model="form.NFTName"></el-input>
          </el-form-item>
          <el-form-item label="上架数量：" prop="onlineCount">
            <el-input v-model="form.onlineCount"></el-input>
          </el-form-item>
          <el-form-item label="单笔最大购买数量:" prop="buyCountOnce">
            <el-input v-model="form.buyCountOnce" type="number"></el-input>
          </el-form-item>
          <el-form-item label="单用户最大购买数量:" prop="maxCountPerUser">
            <el-input v-model="form.maxCountPerUser" type="number"></el-input>
          </el-form-item>
          <el-form-item label="是否新人专享:" prop="isNewUserBuy">
            <el-radio v-model="form.isNewUserBuy" :label="isNewUserBuyTrue"
              >是</el-radio
            >
            <el-radio v-model="form.isNewUserBuy" :label="isNewUserBuyFalse"
              >否</el-radio
            >
          </el-form-item>
          <el-form-item
            label="用户注册时间:"
            prop="isNewUserBuyTime"
            v-if="form.isNewUserBuy === true"
          >
            <el-date-picker
              v-model="form.isNewUserBuyTime"
              type="datetimerange"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="doChange"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div style="margin: 45px 0 10px 45px">
          优先购：<el-switch v-model="isValue"></el-switch>
        </div>
      </el-card>

      <!-- 优先购表单 -->
      <el-card class="mb30" v-if="isValue">
        <div slot="header">
          <span>优先购设置：</span>
        </div>
        <div class="x-form-body" ref="formBody">
          <el-form
            class="x-form"
            ref="valueForm"
            size="small"
            label-width="140px"
            :rules="oRules"
            :model="priorityForm"
            label-position="right"
          >
            <el-form-item label="快照时间：" prop="aheadBuyUserTime">
              <el-date-picker
                v-model="priorityForm.aheadBuyUserTime"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="起止时间：" prop="dateRange">
              <el-date-picker
                type="datetimerange"
                range-separator="——"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="priorityForm.dateRange"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="单账户允许购买数量："
              prop="aheadBuyNumLimit"
              label-width="210px"
            >
              <el-input
                v-model="priorityForm.aheadBuyNumLimit"
                type="number"
              ></el-input
            ></el-form-item>
            <el-form-item
              label="优先购额度总量："
              prop="aheadBuyNumTotal"
              label-width="182px"
            >
              <el-input
                v-model="priorityForm.aheadBuyNumTotal"
                type="number"
              ></el-input
            ></el-form-item>
            <el-row>
              <el-col :span="9">
                <el-form-item
                  label="设置优先购权限(持有一下藏品的用户可参与优先购)："
                  prop="checkedCities"
                  label-width="auto"
                  v-model="priorityForm.checkedCities"
                >
                  <el-tag
                    v-for="(tag, index) in priorityForm.checkedCities"
                    :key="index"
                    closable
                    @close="handleClose(tag)"
                  >
                    {{ tag.NFTName }}
                  </el-tag>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="mt10 x-q-search" ref="top">
          <el-row :gutter="20">
            <el-col :span="14">
              <div class="x-con-item">
                <el-input
                  placeholder="请输入藏品名称"
                  v-model="content"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="10">
              <el-button
                type="primary"
                size="mini"
                class="x-q-btn"
                @click="doQuery()"
                >筛选</el-button
              >
              <el-button type="text" @click="doQueryReset">重置</el-button>
            </el-col>
          </el-row>
          <div style="margin-top: 10px">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="priorityForm.checkedCities"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                v-for="(city, index) in cities"
                :label="city"
                :key="index"
                >{{ city.NFTName }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </el-card>
      <el-row type="flex" justify="center" class="mt5">
        <el-col :span="6">
          <el-button type="primary" @click="doValidate">提交</el-button>
          <el-button @click="goBack">返回上级页面</el-button>
        </el-col>
      </el-row>
      <div class="x-q-imgTip">藏品图片或3D模型：</div>
      <div class="x-q-img">
        <el-image
          v-if="imgUrl !== '' || imgUrl === undefined"
          style="width: 100%; height: 100%; border-radius: 10px"
          :src="imgUrl"
          :fit="contain"
        ></el-image>
        <span v-else>输入藏品ID后可查看</span>
      </div>
    </main>
  </div>
</template>

<script>
import { BrandQuery } from "@/api/module/brand";
import { CollectionQuery } from "@/api/module/collection";
import { NftQuery } from "@/api/module/nft";
import { MarketCreate, PriceQuery } from "@/api/module/market";

// 表单校验规则
const titleRules = {
  isNewUserBuyTime: [
    {
      required: true,
      message: "请选择用户注册起始时间",
      trigger: "change",
    },
  ],
  isNewUserBuy: [
    {
      required: false,
      message: "请选择是否真人专享",
      trigger: "change",
    },
  ],
  sellType: [
    {
      required: true,
      message: "请选择藏品类型",
      trigger: "change",
    },
  ],
  buyCountOnce: [
    {
      required: true,
      message: "请输入单笔最大购买数量",
      trigger: "blur",
    },
  ],
  maxCountPerUser: [
    {
      required: false,
      message: "请输入单用户最大购买数量",
      trigger: "blur",
    },
  ],
  brandID: [
    {
      required: true,
      message: "请选择品牌方",
      trigger: "change",
    },
  ],
  collectionID: [
    {
      required: true,
      message: "请选择藏品集",
      trigger: "change",
    },
  ],
  NFTID: [
    {
      required: true,
      message: "请输入藏品ID",
      trigger: "blur",
    },
  ],
  NFTName: [
    {
      required: true,
      message: "请输入藏品名称",
      trigger: "blur",
    },
  ],
  onlineCount: [
    {
      required: true,
      message: "请输入上架数量",
      trigger: "blur",
    },
  ],
  unitPrice: [
    {
      required: true,
      message: "请输入单价",
      trigger: "change",
    },
  ],
  onlineTime: [
    {
      required: true,
      message: "请选择上架时间",
      trigger: "change",
    },
  ],
  onsellTime: [
    {
      required: true,
      message: "请选择开售时间",
      trigger: "change",
    },
  ],
};
const oRules = {
  aheadBuyUserTime: [
    {
      required: true,
      message: "请选择快照时间",
      trigger: "change",
    },
  ],
  dateRange: [
    {
      required: true,
      message: "请选择起止时间",
      trigger: "change",
    },
  ],
  aheadBuyNumLimit: [
    {
      required: true,
      message: "请输入单用户最大购买量",
      trigger: "blur",
    },
  ],

  aheadBuyNumTotal: [
    {
      required: true,
      message: "请输入优先购额度总量",
      trigger: "blur",
    },
  ],
  checkedCities: [
    {
      required: true,
      message: "请选择藏品",
      trigger: "change",
    },
  ],
};

export default {
  name: "MarketCreate",
  data() {
    return {
      isNewUserBuyFalse: false,
      isNewUserBuyTrue: true,
      priceDisable: false,
      checkAll: false,
      cities: [],
      isIndeterminate: true,
      isValue: true,
      oRules: oRules,
      content: "",
      optionsBrand: [],
      optionsCollection: [],
      titleRules: titleRules,
      form: {
        isNewUserBuyTime: [],
        brandID: "",
        NFTName: "",
        collectionID: "",
        NFTID: "",
        onlineCount: "",
        unitPrice: "",
        onlineTime: "",
        onsellTime: "",
        sellType: "",
        buyCountOnce: "",
        isNewUserBuy: false,
        maxCountPerUser: "",
      },
      priorityForm: {
        aheadBuyUserTime: "",
        dateRange: [],
        aheadBuyNumLimit: "",
        aheadBuyNumTotal: "",
        checkedCities: [],
      },
      optionId: [],
      optionsPrice: [],
      imgUrl: "",
      optionsBrand: [],
      optionsCollection: [],
      loading: false,
    };
  },
  created() {
    this.getPackage();
    this.doQuery();
  },
  beforeUpdate() {
    this.$nextTick(() => {
      let a = this.$refs.top.offsetHeight;
      this.$refs.formBody.style.minHeight = a + 400 + "px";
    });
  },

  methods: {
    doSellTypeChange(val) {
      val == "2"
        ? ((this.form.unitPrice = 0), (this.priceDisable = true))
        : ((this.form.unitPrice = ""), (this.priceDisable = false));
    },
    handleClose(val) {
      const tags = this.priorityForm.checkedCities.filter(
        (item) => item.id !== val.id
      );
      this.priorityForm.checkedCities = tags;
    },

    async doQuery() {
      let query = this.setQueryParams();
      const r = await NftQuery(query);
      this.cities = r.list;
    },
    // 设置筛选
    setQueryParams() {
      let query = `?pagination=false&page=1&pageSize=10&status=3`;
      if (this.content) {
        query += `&like=${this.content}`;
      }
      return query;
    },

    // 重置筛选
    doQueryReset() {
      (this.content = ""), (this.priorityForm.checkedCities = []);
      this.doQuery();
    },
    handleCheckAllChange(val) {
      this.priorityForm.checkedCities = val ? this.cities : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    async getCollectionOptions() {
      const rc = await CollectionQuery(
        `?pagination=false&current=1&pageSize=10&brandID=${this.form.brandID}`
      );
      this.optionsCollection = rc.list;
    },
    handleRemove(file, fileList) {
      this.form.treeImg = this.form.treeImg.filter((val, index) => {
        return val.uid != file.uid;
      });
    },

    doIdQuery(e) {
      let query = `?pagination=false&current=1&pageSize=10&like=${e}`;
      if (e !== "") {
        this.loading = true;
        setTimeout(async () => {
          const r = await NftQuery(query);
          this.loading = false;
          this.optionId = r.list;
        }, 200);
      } else {
        this.optionId = [];
      }
    },
    setBaseFormOpenId(val) {
      if (!val) {
        this.imgUrl = "";
      }
      let openId, url, nftName;
      this.optionId.forEach((item) => {
        if (item.id == val) {
          openId = item.id;
          url = item.NFTUrl;
          nftName = item.NFTName;
        }
      });
      this.form.NFTID = openId;
      this.imgUrl = url;
      this.form.NFTName = nftName;
    },
    // 获取选项
    async getPackage() {
      const rb = await BrandQuery(`?pagination=false&current=1&pageSize=10`);
      const rp = await PriceQuery();
      this.optionsPrice = rp.list;
      this.optionsBrand = rb.list;
    },
    selectValue() {
      const f = this.form;
      const p = this.priorityForm;
      let oData = {
        aheadBuyEnable: "2",
        NFTID: f.NFTID,
        brandID: f.brandID,
        collectionID: f.collectionID,
        onlineCount: Number(f.onlineCount),
        onlineTime: f.onlineTime,
        onsellTime: f.onsellTime,
        NFTName: f.NFTName,
        unitPrice: Number(f.unitPrice),
        sellType: f.sellType,
        buyCountOnce: Number(f.buyCountOnce),
        maxCountPerUser: Number(f.maxCountPerUser),
        isNewUserBuy: Boolean(f.isNewUserBuy),
        regStartTime: f.isNewUserBuyTime[0],
        regEndTime: f.isNewUserBuyTime[1],
      };
      if (this.isValue) {
        const checkListId = [];
        p.checkedCities.forEach((it) => checkListId.push(it.id));
        oData = {
          ...oData,
          aheadBuyEnable: "1",
          aheadBuyStartTime: p.dateRange[0],
          aheadBuyEndTime: p.dateRange[1],
          aheadBuyNumLimit: Number(p.aheadBuyNumLimit),
          aheadBuyNumTotal: Number(p.aheadBuyNumTotal),
          aheadBuyUserTime: p.aheadBuyUserTime,
          aheadBuyNFTID: checkListId,
        };
      }
      return oData;
    },
    // 验证表单
    doValidate() {
      this.$refs["titleForm"].validate((valid) => {
        if (valid) {
          if (this.isValue) {
            this.$refs["valueForm"].validate((oValid) => {
              if (oValid) {
                this.doSubmit();
              } else {
                return false;
              }
            });
          } else {
            this.doSubmit();
          }
        } else {
          return false;
        }
      });
    },

    // 创建公告请求
    doSubmit(ns) {
      const param = this.selectValue();
      MarketCreate(param).then((r) => {
        console.log(r);
        if (r.error == null) {
          this.$message({
            type: "success",
            message: "创建成功",
            duration: 1300,
            onClose: () => {
              this.goBack();
            },
          });
        }
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
.x-main {
  position: relative;
  .x-q-imgTip {
    position: absolute;
    top: 187px;
    left: 470px;
  }
  .x-q-img {
    text-align: center;
    line-height: 180px;
    background-color: rgb(243, 241, 241);
    position: absolute;
    border-radius: 5px;
    top: 134px;
    left: 616px;
    width: 360px;
    height: 180px;
    border: 1px dashed rgb(223, 220, 220);
  }
  .x-q-search {
    position: absolute;
    bottom: 130px;
    right: 400px;
    border-left: 1px solid #ccc;
    width: 40%;
    padding-left: 40px;
  }
  /deep/ .el-tag {
    margin-left: 8px;
    margin-top: 8px;
  }
}
</style>